import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/base-query-with-reauth'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    'Alerts',
    'AppSettings',
    'AppSettingsHistory',
    'Chats',
    'ChatMessages',
    'Workspaces',
    'Sagas',
    'Users',
    'Members',
    'WorkspaceMembersOptions',
    'WorkspacesOptions',
    'Spaces',
    'SpaceMembers',
    'SpaceAIPlugin',
    'SpacePluginToken',
    'SingleSpace',
    'MemberStatus',
    'MemberAnswers',
    'Feedback',
    'Starters',
    'AppProducts',
    'AnalyzedChats'
  ]
})
