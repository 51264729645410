import { apiSlice } from './api-splice'
import { getQueryParamString } from 'utils/get-query-param-string'

import {
  AppProductItem,
  AppProductList,
  AppProductListQuery,
  CreateAppProduct,
  Starter,
  StarterList,
  StarterListQuery
} from '@outcoreaix/pto/dist/app-templates'

export const conversationStartersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppProductNames: builder.query<{ id: number; name: string }[], void>({
      query: (body) => ({
        url: 'app-templates/app-product-names',
        method: 'GET'
      })
    }),
    getAppProduct: builder.query<AppProductItem, number>({
      query: (id) => ({
        url: `app-templates/app-product/${id}`,
        method: 'GET'
      }),
      providesTags: ['AppProducts']
    }),
    getAppProductList: builder.query<AppProductList, AppProductListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `app-templates/app-product-list?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: ['AppProducts']
    }),
    createAppProduct: builder.mutation<void, CreateAppProduct>({
      query: (body) => ({
        url: 'app-templates/app-product',
        method: 'POST',
        body
      }),
      invalidatesTags: ['AppProducts']
    }),
    createSpaceFromTemplate: builder.mutation<void, { productId: number; workspaceId: number }>({
      query: ({ productId, workspaceId }) => ({
        url: `sagas/app-templates/create-space-from-template/${productId}/${workspaceId}`,
        method: 'POST'
      }),
      invalidatesTags: ['Spaces']
    }),
    updateAppProduct: builder.mutation<void, { product: CreateAppProduct; productId: number }>({
      query: ({ product, productId }) => ({
        url: `sagas/app-templates/app-product/${productId}`,
        method: 'PATCH',
        body: product
      }),
      invalidatesTags: ['AppProducts', 'Spaces']
    }),
    deleteAppProduct: builder.mutation<void, number>({
      query: (productId) => ({
        url: `app-templates/app-product/${productId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['AppProducts']
    })
  })
})

export const {
  useGetAppProductNamesQuery,
  useGetAppProductQuery,
  useGetAppProductListQuery,
  useCreateAppProductMutation,
  useCreateSpaceFromTemplateMutation,
  useUpdateAppProductMutation,
  useDeleteAppProductMutation
} = conversationStartersApi
