import { useState, FC } from 'react'
import { Form, Select } from 'antd'
import { toast } from 'react-toastify'

import { useCreateSpaceFromTemplateMutation, useGetAppProductNamesQuery } from 'api/app-product-api'

type Props = {
  workspaceId?: number
  setIsLoading: (value: boolean) => void
  onSubmit?: () => void
}

const CreateSpaceForm: FC<Props> = ({ workspaceId, setIsLoading, onSubmit }) => {
  const [form] = Form.useForm()
  const allValues = Form.useWatch([], form)

  const [enableAI, setEnableAI] = useState<boolean>(false)

  const { data, isLoading } = useGetAppProductNamesQuery()
  const [createSpaceFromTemplate] = useCreateSpaceFromTemplateMutation()

  // const handleEnableAIClick = (checked: boolean) => {
  //   setEnableAI(checked)
  // }

  const handleOnFinish = async (data: { template_id: number }) => {
    if (!workspaceId) return
    setIsLoading(true)
    createSpaceFromTemplate({ productId: data.template_id, workspaceId })
      .unwrap()
      .then(() => {
        toast.success(`Successfully created space!`)
        onSubmit?.()
      })
      .catch((err) => {
        toast.success(err)
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <Form
      layout="vertical"
      onFinish={handleOnFinish}
      form={form}
      name="create_space_form"
      className="lg:min-w-[480px] pt-6"
    >
      <Form.Item
        label="Product Template"
        name="template_id"
        rules={[{ required: true, message: `Product Template is required` }]}
      >
        <Select placeholder="Select an option">
          {data?.map((el) => (
            <Select.Option value={el.id} key={el.id}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* <Form.Item label="Enable AI Configuration" valuePropName="checked">
            <Switch checked={enableAI} onChange={handleEnableAIClick} />
          </Form.Item>

          {enableAI && (
            <ApiConnectionItem
              setValue={() => {}}
              apiEndpoint={allValues.apiEndpoint}
              apiKey={allValues.apiKey}
              model={allValues.model}
            />
          )} */}
    </Form>
  )
}

export default CreateSpaceForm
