import { Form, FormInstance, Typography, Upload } from 'antd'
import { FC } from 'react'
import { extractFileNameFromURL } from 'utils/extract-file-name-from-url'

type Props = {
  name?: string
  kbUrl?: string
  validateFields: FormInstance['validateFields']
}

const KnowledgeBaseItem: FC<Props> = ({ kbUrl, name, validateFields }) => {
  return (
    <Form.Item
      name={name ? name : 'kb_file'}
      label="Please upload a Knowledge Base document"
      rules={[{ required: true, message: 'Please upload a Knowledge Base document' }]}
    >
      <Form.Item name={name ? name : 'kb_file'} valuePropName="file" getValueFromEvent={(event) => event?.file} noStyle>
        <Upload.Dragger
          name="file"
          {...(kbUrl && {
            defaultFileList: [
              {
                uid: '1',
                name: extractFileNameFromURL(kbUrl),
                url: kbUrl
              }
            ]
          })}
          customRequest={async (info) => {
            await validateFields().catch(() => {})
            info.onSuccess?.('Ok')
          }}
          beforeUpload={(file) => {
            return new Promise((resolve, reject) => {
              if (file.size > 1024 * 1024 * 5) {
                reject('File size exceeded')
              } else {
                resolve('Success')
              }
            })
          }}
          maxCount={1}
          accept=".csv,.doc,.docx,.pdf,.txt"
          style={{ padding: '25px 60px' }}
          onDrop={(e) => e.stopPropagation()}
        >
          <Typography.Text>
            <strong>Click to upload</strong> or drag and drop DOC, DOCX, CSV, TXT, PDF
          </Typography.Text>
        </Upload.Dragger>
      </Form.Item>
    </Form.Item>
  )
}

export default KnowledgeBaseItem
