import { Form } from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload'
import { FC } from 'react'
import { KnowledgeBaseItem } from './components'

export type Values = {
  kb_file: UploadFile<RcFile>
}

type Props = {
  kbUrl?: string
  name?: string
  onSubmit: (data: Values) => Promise<void>
  disabled?: boolean
}

const KnowledgeBaseForm: FC<Props> = ({ kbUrl = '', name = 'workspace_knowledgebase', onSubmit, disabled = false }) => {
  const [form] = Form.useForm()
  return (
    <Form
      initialValues={{ kb_file: kbUrl }}
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      name={name}
      disabled={disabled}
    >
      <KnowledgeBaseItem kbUrl={kbUrl} validateFields={form.validateFields} />
    </Form>
  )
}

export default KnowledgeBaseForm
